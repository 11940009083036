@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
#root{
  overflow-x: hidden;
  .rdt_Table .rdt_TableBody, .rdt_TableRow, .rdt_Pagination, .rdt_Table>div {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 250 / var(--tw-bg-opacity));
  }
  .rdt_TableHeader{
    display: none;
  }
  .rdt_TableHeadRow{
    --tw-text-opacity: 1;
    --tw-bg-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    background-color: rgb(20 184 166/var(--tw-bg-opacity));
  }
  .rdt_TableCell{
    padding-left: 4px;
    padding-right: 4px;
  }
  .book-table{
    border: 1px solid black;
    border-collapse: collapse;
    table-layout: fixed;
    width: 240px;
    th, td {
      border: 1px solid black;
      border-collapse: collapse;
    }
    th:first-of-type,th:last-of-type, td:first-of-type,td:last-of-type{
      width:25px;
    }
  }
  .device-menu{
    .device-menu-dropdown{
      display: none;
    }
    &:active .device-menu-dropdown,&:hover .device-menu-dropdown{
      display: block;
    }
  }
  textarea {
    resize: none;
  }
}